.reachOutOuterCont {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0px;
  background-color: #33333370;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 999;
  overflow-y: auto;
}

.reachOutOuterBg{
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0px;
  background-color: #333;
  opacity: 0.85;
  overflow: auto;
}

.reachOutCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: auto;
  overflow-y: auto;
  padding: 50px 10px;
  margin-top: auto;
  margin-bottom: auto;
  max-height: calc(100vh - 160px);
  width: 40vw;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.05);
  transition: 0.5s;
  animation: slide-top 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.reachOutCont {
  text-align: center;
}

.reachOutCont > div > * {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.cross {
  position: absolute;
  top: 50px;
  right: 30px;
  cursor: pointer;
  width: 20px;
  height: auto;
  padding: 10px;
  border-radius: 50%;
  background-color: #eee;
}

.reachOutHeading {
  font-family: 'Roboto Mono';
  width: calc(100% - 60px);
  font-size: 24px;
  margin-top: 0px;
}

.reachOutSubHeading {
  font-family: 'Roboto Mono';
  width: calc(100% - 80px);
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 15px;
}

.input {
  all: unset;
  width: auto;
  padding: 15px 40px;
  background: #eee;
  border-radius: 10px;
  margin-bottom: 10px;
  font-family: 'Roboto Mono';
  padding: 15px 30px;
  width: calc(100% - 140px);
  font-size: 15px;
  text-align: left !important;
}

.btn {
  all: unset;
  cursor: pointer;
  font-family: 'Roboto Mono';
  width: calc(100% - 200px);
  padding: 20px 60px;
  font-size: 15px;
  background: #eee;
  border-radius: 10px;
  margin-bottom: 10px;
  color: black;
  transition: 0.3s;
}

.btnFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btnFlex > * {
  margin: 0px;
  padding: 3px;
}

.btn:hover {
  transform: scale(1.02);
}

.btnPrimary {
  background: #e66379;
  color: white;
}

 @-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}


@media only screen and (max-width: 1200px) {
  .reachOutCont {
    width: 70vw;
  }
}


@media only screen and (max-width: 600px) {
  .input {
    width: calc(100% - 60px);
  }
  .btn {
    width: calc(100% - 120px);
  }
  .reachOutCont {
    width: calc(100vw - 40px);
    height: calc(100vh - 250px);
    margin-bottom: auto !important;
    margin-top: 30px !important;
    top: 0px;
  }
}