.coursesCont {
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .coursesIntroCont {
    width: calc(100% - 0px) !important;
    padding: 20px;
  }
  .coursesIntroCont > * {
    flex-wrap: wrap;
    max-width: calc(100% - 80px);
  }
  .coursesIntroImg {
    margin-left: -1% !important;
  }
}