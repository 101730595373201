/* animation keyframes and classes */

.slide-top-fade {
	-webkit-animation: slide-top-fade 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top-fade 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 0.8s;
}

 @-webkit-keyframes slide-top-fade {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 1;
  }
}
@keyframes slide-top-fade {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 1;
  }
}


.slide-left-fade {
	-webkit-animation: slide-left-fade 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left-fade 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 1s;
}

 @-webkit-keyframes slide-left-fade {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 1;
  }
}
@keyframes slide-left-fade {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 1;
  }
}


/* loading animations */

.loadingAnimation {
	-webkit-animation: loadingAnimation 3s linear;
	        animation: loadingAnimation 3s linear;
  opacity: 0;
}

@-webkit-keyframes loadingAnimation {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  10% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
  90% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
}
@keyframes loadingAnimation {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  10% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
  90% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
}



.o {
	-webkit-animation: o 0.4s linear;
	        animation: o 0.4s linear;
}

@-webkit-keyframes o {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes o {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

.i {
	-webkit-animation: i 0.4s linear;
	        animation: i 0.4s linear;
}

@-webkit-keyframes i {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes i {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

.b {
	-webkit-animation: b 0.4s linear;
	        animation: b 0.4s linear;
}

@-webkit-keyframes b {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes b {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}


.r {
	-webkit-animation: r 0.4s linear;
	        animation: r 0.4s linear;
}

@-webkit-keyframes r {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes r {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}


.e {
	-webkit-animation: e 0.4s linear;
	        animation: e 0.4s linear;
}

@-webkit-keyframes e {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes e {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}