.Service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 40px 40px 40px;
  width: calc(100% - 120px);
  height: auto;
  overflow: hidden;
}

.servicesIntro {
  background-color: #eee;
  border-radius: 20px;
  width: calc(100% - 120px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 40px 60px 40px 60px;
  height: auto;
  overflow: hidden;
  margin-top: -40px;
}

.heroImg {
  width: 60%;
  margin: 40px;
}

.servicesIntroText {
  font-size: 60px;
  font-family: 'Roboto Mono';
  padding: 0px;
  margin: 0px;
  flex: 1;
  line-height: 100px;
  margin-left: 30px;
  flex: 1;
  text-align: left;
}

.servicesIntroText > span {
  /* font-size: 120px; */
  background-color: white;
  padding: 10px 40px 10px 40px;
  border-radius: 10px;
}

.servicesIntroImg {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.servicesIntroImg > img {
  width: 70%;
  height: auto;
  margin-left: auto;
}
