.Works {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 40px 40px 40px;
  width: calc(100vw - 160px);
  height: auto;
  overflow: auto;
  overflow-x: hidden;
}

.worksIntro {
  background-color: #eee;
  border-radius: 20px;
  width: calc(100% - 40px);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 60px 40px 60px;
  overflow: hidden;
  margin-top: -40px;
}

.heroImg {
  width: 40%;
  margin: 20px;
}

.introSubText__works {
  margin-top: 90px;
  margin-bottom: 50px;
}

.worksBtn {
  width: auto;
  padding-left: 60px;
  padding-right: 60px;
}

.servicesIntroCarouselSubLabel {
  display: flex !important;
  font-size: 18px !important;
}

.servicesIntroCarouselLabel {
  font-size: 27px !important;
  margin-bottom: 50px;
}


@media only screen and (max-width: 600px) {
  .heroImg {
    width: 85%;
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .worksIntro {
    width: calc(100% - 80px);
  }
  .Works {
    padding: 40px 20px 40px 20px;
    width: calc(100vw - 80px);
  }
}