.Team {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 40px 40px 40px;
  width: calc(100% - 80px);
  height: auto;
  overflow: hidden;
}

.teamIntro {
  background-color: #eee;
  border-radius: 20px;
  width: calc(100% - 120px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 40px 60px 40px 60px;
  height: auto;
  overflow: hidden;
  margin-top: -40px;
}

.teamIntro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.teamIntroTextCont {
  padding: 0px;
  margin: 0px;
  flex: 2;
  margin-left: 30px;
}

.teamIntroText {
  text-align: left;
  font-size: 86px;
  font-family: 'Roboto Mono';
}

.teamIntroSubText {
  font-size: 26px;
  font-family: 'Roboto Mono';
  text-align: left;
  margin-top: -40px;
  width: 100%;
}

.teamIntroText > span {
  /* font-size: 120px; */
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}

.teamIntroImg {
  flex: 3;
  height: auto;
}

.teamIntroImg > img {
  height: auto;
  width: 70%;
  margin-left: 20%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.introTextCont {
  margin-top: 20px;
}

.introText {
  font-size: 86px;
  font-family: 'Roboto Mono';
}

.introSubText {
  font-size: 23px;
  font-family: 'Roboto Mono';
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.profileTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 60px;
  gap: 30px;
}

.profileTab {
  flex: 1;
  max-width: 33.33%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  height: auto;
  padding: 30px;
  border-radius: 20px;
  transition: 0.25s !important;
  cursor: pointer;
}

.profileTab:hover {
  transform: scale(1.035) !important;
}

.description {
  margin-top: 30px;
  margin-bottom: 40px;
  font-family: 'Roboto Mono';
}

.profileTab:hover .description > a {
  color: white
}

.profileImg > img {
  width: auto;
  height: 400px;
  margin-top: 30px;
  margin-bottom: 30px;
  z-index: 2;
}

.profileImg {
  z-index: 2;
}

.revv {
  max-width: 30% !important;
}

.brain {
  max-width: 30% !important;
}

.revvImg {
  height: auto !important;
  width: 300px !important;
  margin-right: -80px;
  margin-top: -10px !important;
  margin-bottom: 90px !important;
}

.brainImg {
  height: auto !important;
  width: 300px !important;
  margin-top: 110px !important;
  margin-bottom: 90px !important;
}

.name {
  font-family: 'Roboto Mono';
  font-size: 23px;
}

.vamsi:hover {
  background-color: #e66379;
  color: white;
}

.rachana:hover {
  background-color: #0080f3;
  color: white;
}

.vinay:hover {
  background-color: #e3eb4c;
  color: black;
}

.revv:hover {
  background-color: #c51ca6;
  color: white;
}

.brain:hover {
  background-color: #f6ff4e;
  color: black;
}

.bgCircle_vamsi {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #fff;
  z-index: -1;
  margin-top: -350px;
  margin-left: -30px;
  transition: 0.5s;
}

.bgCircle_vinay {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #fff;
  z-index: -1;
  margin-top: -350px;
  margin-left: -40px;
  transition: 0.5s;
}

.bgCircle_rachana {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #fff;
  z-index: -1;
  margin-top: -350px;
  transition: 0.5s;
}

.bgCircle_brain {
  position: absolute;
  width: 200px;
  height: 350px;
  border-radius:20px;
  background-color: #fff;
  z-index: -1;
  margin-top: -385px;
  margin-left: 50px;
  transition: 0.5s;
}

.bgCircle_revv {
  position: absolute;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background-color: #fff;
  z-index: -1;
  margin-top: -390px;
  margin-left: -60px;
  transition: 0.5s;
}

.profileTab:hover .bgCircle_rachana {
  background-color: #035eae;
}

.profileTab:hover .bgCircle_vamsi {
  background-color: #df3a55;
}

.profileTab:hover .bgCircle_vinay {
  background-color: #c0ca00;
}

.profileTab:hover .bgCircle_brain {
  background-color: #d4df03;
}

.profileTab:hover .bgCircle_revv {
  background-color: #a70a8a;
}

.videoContainer {
  margin-top: 50px;
  width: calc(100% - 80px);
  background: #eee;
  border-radius: 20px;
  padding: 40px;
}

.videoPlayer {
  width: 100%;
  height: auto;
}



/* responsiveness */

@media only screen and (max-width: 1400px) {
  .teamIntroText {
    font-size: 80px;
  }
  .teamIntroImg > img {
    width: 65%;
    margin-left: 40%;
  }
}

@media only screen and (max-width: 1200px) {
  .Team {
    width: calc(100vw - 80px);
  }
  .teamIntroText {
    font-size: 76px;
  }
  .teamIntroImg > img {
    width: 80%;
    margin-left: 30%;
  }
}

@media only screen and (max-width: 1100px) {
  .Team {
    width: calc(100vw - 80px);
  }
  .teamIntro {
    flex-direction: column;
  }
  .teamIntroText {
    font-size: 86px !important;
    text-align: center;
    width: 100%
  }
  .introText {
    font-size: 86px !important;
  }
  .teamIntroSubText {
    text-align: center;
    margin-left: -10px;
  }
  .teamIntroImg > img {
    width: 100%;
    margin-left: 5%;
    margin-top: 20%;
  }
}

@media only screen and (max-width: 766px) {
  .teamIntroText {
    font-size: 56px !important;
    text-align: center;
    width: calc(100% - 30px)
  }
  .introText {
    font-size: 56px !important;
  }
  .teamIntroSubText {
    text-align: center;
    margin-left: -10px;
  }
}

@media only screen and (max-width: 600px) {
  .teamIntro {
    flex-direction: column;
  }
  .Team {
    padding: 40px 20px 40px 20px;
    width: calc(100% - 80px);
    margin: auto;
  }
  .teamIntroText {
    font-size: 46px !important;
    text-align: center;
    width: calc(120% - 25px);
    margin-left: -10%;
  }
  .introText {
    font-size: 46px !important;
  }
  .teamIntroSubText {
    text-align: center;
    width: 130%;
    margin-left: calc(-15% - 10px);
    font-size: 18px;
    margin-top: 20px;
  }
  .teamIntroImg > img {
    width: 110%;
    margin-left: 12%;
    margin-top: 20%;
  }
  .introSubText {
    font-size: 17px !important;
    width: 100%;
  }
  .profileTabs {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .profileTab {
    width: calc(100% - 120px) !important;
    max-width: calc(100% - 40px);
  }
  .revv {
    width: calc(100% - 120px) !important;
    max-width: calc(100% - 40px) !important;
  }
  .bgCircle_revv {
    width: 290px;
    height: 290px;
    margin-top: -350px;
    margin-left: -35px;
  }
  .brain {
    width: calc(100% - 140px) !important;
    max-width: calc(100% - 40px) !important;
  }
}

@media only screen and (max-width: 440px) {
  .teamIntroImg > img {
    width: 100%;
    margin-left: 25%;
    margin-top: 20%;
  }
  .teamIntroText {
    font-size: 46px !important;
    text-align: center;
    width: 100%;
    margin-left: -20px;
  }
  .introText {
    font-size: 46px !important;
  }
  .introSubText {
    font-size: 17px !important;
  }
  .teamIntroSubText {
    text-align: center;
    width: 130%;
    margin-left: calc(-15% - 10px);
    font-size: 16px;
    margin-top: 20px;
  }
}

