.home {
    width: calc(100vw - 80px);
    margin-top: 0px;
    padding: 0px 40px 0px 40px;
    height: auto;
}

.headerSubText {
  font-family: 'Roboto Mono';
  text-align: center;
  margin-left: auto;
  margin: auto;
  font-size: 26px;
  width: calc(60% - 40px);
}

.homeIntro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #eee;
  border-radius: 20px;
  margin-bottom: 50px;
  padding: 60px 60px;
  min-height: calc(100vh - 220px);
}

.homeSubtext {
    font-size: 43px;
    font-family: 'Roboto Mono';
}

.playBtn {
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #e66379;
  transition: 0.5s;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.playBtn svg {
  width: 25%;
  height: auto;
  margin-top: 2px;
  margin-left: 6px;
}

.playBtn > svg > path {
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35)
}

.playBtn:hover {
  transform: scale(1.15);
}

.mainCard {
    margin-left: 15px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 60px;
    width: calc(100% - 100px);
    height: auto;
    margin-bottom: auto;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.lastSubText {
  margin-top: 100px !important;
  margin-bottom: 100px;
}

.mainCard div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 96px;
  font-weight: 400;
  font-family: 'Roboto Mono';
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  padding: 0px;
  width: auto;
  max-width: 60%;
}

.mainCard > div > p {
  padding: 0px;
  margin: 0px;
  line-height: 100px;
  text-align: center;
  font-size: 96px;
  font-weight: 400;
}

.mainCard > p {
  font-size: 96px;
  font-family: 'Roboto Mono';
  width: auto;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 0px;
  padding-top: 0px;
}

.headerText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: 20%;
    text-align: center;
    background: yellow !important;
}

.headerText > p {
    text-align: left;
    font-size: 86px;
    font-family: 'Roboto Mono';
    padding: 0px;
    margin: 0px;
    background-color: #0080f3;
}

.headerText > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 0px;
    background-color: #e66379;
}

.headerText > div > p {
    text-align: left;
    font-size: 86px;
    font-family: 'Roboto Mono';
    padding: 0px;
    margin: 0px;
}

.headerText > br {
    margin-bottom: 0px
}

.rotate-center {
	-webkit-animation: rotate-center 1s linear infinite;
	        animation: rotate-center 1s linear infinite;
}

.  {
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 26px;
    font-family: 'Roboto Mono';
}

.headerText > strong {
    font-size: 86px;
    font-weight: 700;
}

.heroImgElement {
    flex: 1;  
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0px;
}

.heroImg {
  width: 550px;
  height: auto;
  margin: auto;
  transition: 1s;
  /* animation: mover 1.5s infinite; */
}

.contact_Cont {
    margin-left: auto;
    width: 150px;
    height: 140px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: calc(50vh - 50px);
    left: calc(60vw - 130px);
  }
  
  .contact {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #e66379;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transform: rotate(20deg);
    transition: 0.3s;
    padding: 20px;
  }
  
  .contact_Cont:hover .contact{
    transform: rotate(0deg);
    /* width: 150px;
    height: 10px;
    border-radius: 6px; */
  }

  .minCtaText {
    cursor: pointer;
    text-align: center;
    font-size: 22px;
    font-family: 'Roboto Mono' !important;
  }

  .minCta {
    cursor: pointer;
    height: 60px;
    width: 30%;
    transition: 0.6s;
    background-color: #e66379;
    padding: 10px 40px;
    color: white;
    border-radius: 10px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    display: flex; 
    flex-direction: row; 
    align-items: center;
    justify-content: center;
  }

  /* .minCta:hover {
    border-bottom: 4px solid #e66379;
  } */

.minCtaImg {
  margin-left: 0px;
  width: 25px;
  height: 25px;
  padding: 0px;
  transition: 0.6s;
  margin-top: 5px;
}

.minCta:hover .minCtaImg {
  margin-left: 20px;
}

.silder > div {
  width: 100vw;
}

.Tilt {
  height: 60vh;
  width: 100vw !important;
  padding-top: 60px;
  padding-bottom: 60px;
}

.homeImage {
  width: 90%;
  height: auto;
  transition: 0.5s;
  margin-left: auto;
  margin-right: auto;
}

.homeIntroCarouselLabel {
  font-size: 22px;
  font-family: 'Roboto Mono';
  margin-bottom: 20px;
  margin-top: 70px;
}

.homeIntroCarouselSubLabel {
  font-size: 22px;
  font-family: 'Roboto Mono';
  display: none;
}

.servicesSlider {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 30px;
  margin-top: 60px;
}

.servicesSliderInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 30px;
  width: calc(40% - 80px);
}

.serviceSliderEL {
  width: calc(100% - 50px);
  background: #eee;
  border-radius: 10px;
  padding: 50px 40px;
  height: auto;
  margin-bottom: 30px;
}

.homeFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 60px;
  border-radius: 20px;
  margin-top: 40px;
  text-align: left;
}

.homeFooterTextCont {
  width: calc(55% - 30px);
  text-align: left;
}

.homeFooterSubText {
  font-size: 22px;
  margin-top: 0px;
  margin-bottom: 60px;
  font-family: 'Roboto Mono';
  text-align: left;
}

.homeFooterText {
  font-size: 96px;
  margin: 0px;
  padding-bottom: 50px;
  font-family: 'Roboto Mono';
}

.homeFooterImg {
  width: calc(45% - 30px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.homeFooterImg > img {
  width: 80%;
  height: auto;
}

.homeFooterBtn{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #e66379;
  width: calc(100% - 20px);
  color: white;
  padding: 5px;
  border-radius: 10px;
  font-family: 'Roboto Mono';
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

@-webkit-keyframes hover {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 20px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes hover {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 20px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

 @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotateZ(0);
              transform: rotateZ(0);
    }
    100% {
      -webkit-transform: rotateZ(360deg);
              transform: rotateZ(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotateZ(0);
              transform: rotateZ(0);
    }
    100% {
      -webkit-transform: rotateZ(360deg);
              transform: rotateZ(360deg);
    }
  }

  @-webkit-keyframes mover {
    0% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    18% {
      -webkit-transform: translateY(-40px);
              transform: translateY(-40px);
    }
    36% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    54% {
      -webkit-transform: translateY(-40px);
              transform: translateY(-40px);
    }
    72% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
  @keyframes mover {
    0% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    18% {
      -webkit-transform: translateY(-40px);
              transform: translateY(-40px);
    }
    36% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    54% {
      -webkit-transform: translateY(-40px);
              transform: translateY(-40px);
    }
    72% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
  
.silder {
  width: auto;
}

/* responsiveness */

@media only screen and (max-width: 1200px) {
  .home {
    width: calc(100vw - 100px);
  }
  .headerSubText {
    width: auto;
  }
  .mainCard > div > p {
    font-size: 76px;
  }
  .homeFooterText {
    font-size: 76px;
  }
  .mainCard > p {
    font-size: 76px;
  }
  .servicesSliderInner {
    width: calc(50% - 60px);
  }
  .homeFooter {
    flex-direction: column-reverse;
  }
  .homeFooterImg {
    transform: rotate(-90deg);
    margin-bottom: 150px;
    margin-top: 100px;
  }
  .homeFooterTextCont {
    width: calc(85% - 30px);
    text-align: left;
  }
  .lastSubText {
    margin-top: 40px !important;
    margin-bottom: 70px;
  }
  .mainCard {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .home {
    width: calc(100vw - 60px);
  }
  .mainCard > p {
    font-size: 66px;
    max-width: 100%;
  }
  .homeFooterText {
    font-size: 66px;
  }
  .mainCard > div > p {
    font-size: 66px;
    line-height: 80px;
  }
  .mainCard > div {
    max-width: 100%;
  }
  .headerSubText {
    width: 85%;
    font-size: 20px;
  }
  .mainCard {
    width: auto;
  }
  .homeIntro {
    padding: 30px 30px;
  }
  .servicesSlider {
    flex-direction: column;
  }
  .serviceSliderEL {
    width: calc(100% - 60px);
  }
  .servicesSliderInner {
    width: calc(100% - 10px);
  }
  .homeFooterTextCont {
    width: calc(95% - 30px);
  }
  .bottonPaddingDiv {
    height: 100px;
    width: 100%
  }
  .homeFooterImg {
    width: 80%;
  }
  .lastSubText {
    margin-top: 40px !important;
    margin-bottom: 70px;
  }
  .mainCard {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 766px) {
  .headerSubText {
    width: calc(100% - 20px);
    text-align: left;
  }
  .mainCard > div > p {
    font-size: 56px !important;
    line-height: 70px;
  }
  .homeFooterText {
    font-size: 56px;
  }
  .mainCard > p {
    font-size: 56px !important;
  }
  .playBtn {
    width: 60px;
    height: 60px;
  }
  .playBtn svg {
    margin-top: 0px;
    margin-left: 4px;
  }
  .homeIntro {
    min-height: calc(50vh - 220px);
  }
  .homeFooterTextCont {
    width: calc(100% - 20px);
  }
  .bottonPaddingDiv {
    height: 150px;
    width: 100%
  }
  .homeFooterImg {
    width: 100%;
  }
  .lastSubText {
    margin-top: 40px !important;
    margin-bottom: 70px;
  }
  .mainCard {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .mainCard > div > p {
    font-size: 48px !important;
    line-height: 60px;
  }
  .mainCard > p {
    font-size: 48px !important;
  }
  .homeFooterText {
    font-size: 48px;
  }
  .homeFooterImg {
    width: 120%;
  }
  .homeFooterTextCont {
    width: 100%;
  }
  .homeFooterImg {
    margin-bottom: 100px;
  }
  .homeFooterBtn {
    width: calc(100% + 10px);
    margin-left: -8px;
    font-size: 12px;
  }
  .homeIntroCarouselSubLabel {
    /* text-align: left; */
    font-size: 17px;
    line-height: 27px;
  }
  .homeFooterS  ubText {
    font-size: 17px;
    margin-top: -10px;
  }
  .homeFooter {
    padding: 30px;
  }
  .headerSubText {
    font-size: 17px;
  }
  .lastSubText {
    margin-top: 40px !important;
    margin-bottom: 70px;
  }
  .mainCard {
    margin-top: 20px;
  }
  .homeIntro {
    width: calc(100% - 40px);
    margin-left: -10px;
  }
}

@media only screen and (max-width: 440px) {
  .mainCard > div > p {
    font-size: 40px !important;
    line-height: 50px;
  }
  .mainCard > p {
    font-size: 40px !important;
  }
  .homeFooterText {
    font-size: 40px;
  }
  .introHeroEl {
    width: calc(100% + 20px);
  }
  .lastSubText {
    margin-top: 40px !important;
    margin-bottom: 70px;
  }
  .mainCard {
    margin-top: 20px;
  }
}

