

.App {
  text-align: center;
  background-color: white;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  margin: 0px;
}

.header {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: calc(100vw - 140px);
  align-items: center;
  justify-content: center;
  padding: 20px 50px 20px 50px;
  font-family: 'Roboto Mono';
  font-size: 20px;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  margin-right: 50px;
}

.logo > p {
  font-size: 30px;
  font-family: 'Roboto Mono', monospace;
  padding: 0px;
  font-weight: bold;
  color: #e66379;
  /* background: -webkit-linear-gradient(45deg, #e66379, #19c8f1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.logo > img {
  width: 60px; 
  height: auto;
  margin-right: 30px;
  margin-left: 0px;
}

.navItem {
  padding: 10px 50px 10px 50px;
  cursor: pointer;
  position: relative;
  z-index: 3;
  font-size: 20px;
}

.navItem:after {
  position: absolute;
  content: '';
  height: 3px;
  bottom: -4px;
  border-radius: 3px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 0%;
  background: transparent;
  transition: 0.5s;
  z-index: -1;
}

.navItem:hover::after {
  width: 40%;
  background: #e66379;
}

.showUnderline {
  color: white;
}

.showUnderline::after {
  position: absolute;
  content: '';
  height: 54px;
  bottom: -4px;
  border-radius: 3px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 70%;
  background: #e66379;
  transition: 0.5s;
  z-index: -1;
}

.showUnderline:hover::after{
  width: 80%;
}

.navItem:first-of-type {
  margin-left: auto;
}

.navItem_Contact_Cont {
  margin-left: auto;
  width: 150px;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
}

.navItem_Contact {
  width: 60px;
  height: 60px;
  font-size: 17px;
  border-radius: 50%;
  background-color: #e66379;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  transform: rotate(20deg);
  transition: 0.3s;
  padding: 20px;
}

.navItem_Contact_Cont:hover .navItem_Contact{
  transform: rotate(0deg);
  /* width: 150px;
  height: 10px;
  border-radius: 6px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}


/* loader */ 

.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loader img {
  width: 100px;
  height: auto;
}

.loader > div > p{
  font-size: 50px;
  font-family: 'Roboto Mono', monospace;
  padding: 0px;
  font-weight: bold;
  color: #e66379;
  padding: 0px;
  margin: 0px;
}

.loader > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}

.hamburgerIcon {
  width: 40px;
  height: 40px;
  display: none;
  background: #eee;
  border-radius: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.hamburgerIcon_Img {
  width: 20px;
  height: 20px;
  /* margin-top: 10px; */
}

/* bg */

.bg {
  background-color: #eeeeee;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.1'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}


.bottomNav {
  position: fixed;
  bottom: 0px;
  width: calc(100vw - 40px);
  height: auto;
  background: #fff;
  box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.15);
  z-index: 999;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 20px 15px 20px;
  gap: 20px;
  overflow-x: auto;
}

.bottomNavEl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex: 1;
  padding: 10px;
  transition: 0.3s;
}

.bottomNavElBg {
  background: #eee;
  color: black;
}

.bottomNavElBg > img {
  opacity: 1 !important;
}

.bottomNavEl > img {
  width: 17px;
  margin: 0px;
  padding: 0px;
  height: auto;
  opacity: 0.5;
}

.bottomNavEl > p {
  font-size: 12px;
  font-family: 'Roboto Mono';
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}


/* responsiveness */

@media only screen and (max-width: 1200px) {
  /* .navItem {
    display: none;
  }
  .navItem_Contact_Cont {
    display: none;
  }
  .hamburgerIcon {
    display: flex;
  } */

  .header {
    margin-top: -25px;
  }

  .navItem {
    font-size: 15px;
    padding: 10px 30px 10px 30px;
  }

  .logo > p {
    font-size: 22px;
  }

  .logo > img {
    width: 40px;
    height: auto;
    margin-right: 15px;
  }

  .navItem_Contact {
    width: 50px;
    height: 50px;
    font-size: 14px;
  }

  .showUnderline::after {
    height: 47px;
    width: 90%;
  }
  
  .showUnderline::after:hover {
    width: 95%;
  }
}

@media only screen and (max-width: 900px) {
  /* .navItem_Contact_Cont {
    display: none;
  } */
  .hamburgerIcon {
    display: flex;
  }
  .navItem {
    font-size: 13px;
    padding: 10px 25px 10px 25px;
  }
  .showUnderline::after {
    height: 44px;
    width: 100%;
  }
}

@media only screen and (max-width: 766px) {
  .navItem {
    display: none;
  }
  .header {
    padding: 20px 30px 20px 30px;
    width: calc(100% - 80px);
    margin-top: -25px;
  }
  .bottomNav {
    display: flex;
  }
  .navItem_Contact {
    width: 80px !important;
    height: 80px !important;
    font-size: 11px;
    padding: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 20px 20px 20px 20px;
    width: calc(100% - 50px);
  }
}


.bottonPaddingDiv {
  height: 50px;
  width: 100%
}

@media only screen and (max-width: 900px) {
  .bottonPaddingDiv {
    height: 100px;
    width: 100%
  }
}


/* common helper classes */

.nameBg {
  background: linear-gradient(45deg, #0080f3, orange);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.scaleOnHover:hover {
  transform: scale(1.03);
  transition: 0.3s;
}

.homeFooterBtn {
  transition: 0.3s;
}

.homeFooterBtn:hover {
  transform: scale(1.02) !important;
}

.videoPlayer > div > iframe {
  border-radius: 10px;
}

.introSubAccordian {
  width: 100% !important;
}
